import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

import { StaticImage } from "gatsby-plugin-image"

import BtnWrap from "../components/btn-wrap"
import ImgCon from "../components/img-con"
import SEO from "../components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Blog geografických zajímavostí" />
    <h1>Blog geografických zajímavostí</h1>
    <p>Blog Zeměpisec.cz je místem, kde tu a tam přistane nějaké zajímavost ze světa geografie či GIS. Ty jsou okořeněné špetkou osobních postřehů a hrstičkou autorských map. Vše je s láskou zalité notnou dávkou nešikovných slovních obratů a spojení. Vítejte v pekle, nejmilejší návštěvníci. Pokud Vás nezajímají články, mohla bych třeba doporučit <Link to="/studijni-materialy/">studijní materiály</Link>?</p>
    <hr />
    <h2>Všechny dostupné články</h2>
    <div className="bl-li">
      <article>
        <StaticImage
          src="../images/blog/1.jpg"
          alt=""
          placeholder="dominantColor"
          layout="constrained"
          width={600}
          height={600}
          formats={["auto", "webp", "avif"]}
        />
        <div>
        <h3><Link to="/tropicke-cyklony-vlivem-klimaticke-zmeny-meni-mista-vyskytu/">Tropické cyklóny vlivem klimatické změny mění místa výskytu</Link></h3>
        <p>22. října, 2020</p>
        </div>
      </article>
      <article>
        <StaticImage
        src="../images/blog/2.jpg"
        alt=""
        placeholder="dominantColor"
        layout="constrained"
        width={600}
        height={600}
        formats={["auto", "webp", "avif"]}
        />
        <div>
        <h3><Link to="/zajem-koronavirus-google-casosberna-mapa-grafy-data/">Vyhledávání koronaviru na Google: Časosběrná mapa, grafy a data</Link></h3>
        <p>27. března, 2020</p>
        </div>
      </article>
      <article>
        <StaticImage
        src="../images/blog/3.jpg"
        alt=""
        placeholder="dominantColor"
        layout="constrained"
        width={600}
        height={600}
        formats={["auto", "webp", "avif"]}
        />
        <div>
        <h3><Link to="/pocet-cajoven-v-cr-a-krajich/">Čajovnová velmoc Česko? Počet čajoven v ČR a krajích</Link></h3>
        <p>18. února, 2020</p>
        </div>
      </article>
    </div>
    <hr />
    <p><em>Prosím pozor, probíhá migrace obsahu a některé články ještě chybí. Pardon :)</em></p>


  </Layout>
)
